import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { getData } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';

// Import components
import TableContainer from '../../common/TableContainer';

const SiteConfigTable = (props) => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function getSiteListData() {
            const response = await getData(`/site-config/get-config?siteId=${props?.siteId}`);
            setData([response?.data]);
            console.log("jdsnfns",response.data)
        }
        
        getSiteListData();
    }, []);


    const editRow = () => {
        navigate('/site-config/add-site-config', { state: { siteSettingId: props?.siteId } });
    };

    const columns = useMemo(
        () => [
            {
                header: 'GTM ID',
                accessorKey: 'gtmId',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Generator',
                accessorKey: 'generator',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'TikTok ID',
                accessorKey: 'tiktokId',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Voluum Type',
                accessorKey: 'voluumType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Facebook ID',
                accessorKey: 'fbId',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Vertical',
                accessorKey: 'vertical',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div>
                        <strong>ID:</strong> {row.original.vertical?.id || 'N/A'}<br />
                        <strong>Name:</strong> {row.original.vertical?.name || 'N/A'}<br />
                        <strong>Pabbly:</strong> {row.original.vertical?.pabbly || 'N/A'}
                    </div>
                ),
            },
            {
                header: 'Ringba Users',
                accessorKey: 'ringbaUsers',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => {
                    return row.original.ringbaUsers?.map((user, index) => (
                        <div key={index}>
                            <strong>User {index + 1}:</strong><br />
                            Username: {user.username || 'N/A'}<br />
                            Key: {user.key || 'N/A'}<br />
                            Static No: {user.staticNo || 'N/A'}<br />
                        </div>
                    ));
                },
            },
            {
                header: 'Actions',
                accessorKey: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row: { index } }) => (
                    <>
                        <button
                            className="btn btn-warning btn-sm me-2"
                            onClick={() => editRow(index)}
                        >
                            Edit
                        </button>
                    </>
                )
            }
        ],
        [data]
    );

    // Meta title
    document.title = "User Settings | Skote - React Admin & Dashboard Template";

    return (
        <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search users..."
            pagination="pagination"
            paginationWrapper='dataTables_paginate paging_simple_numbers'
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline align-td-center"
        />
    );
};

SiteConfigTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default SiteConfigTable;

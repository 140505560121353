import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb";
import { postData, patchData, getData } from "../../helpers/api_helper";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AddVertical = (props) => {
    const { id } = useParams();  // Get the id from the URL
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);  // State to track form submission

    // State to manage form input
    const [formData, setFormData] = useState({
        name: "",
        pabblyUrl: "",
        leadUrl: ""
    });

    useEffect(() => {
        if (id) {
            // Fetch the vertical data using the id
            const fetchVerticalData = async () => {
                try {
                    const response = await getData(`/vertical/${id}`);
                    if (response.data) {
                        setFormData(response.data);  // Set form fields with fetched data
                        setIsEditMode(true);  // Switch to edit mode
                    }
                } catch (error) {
                    console.error("Error fetching vertical data:", error);
                    toast.error("Error fetching vertical data.");
                }
            };
            fetchVerticalData();
        }
    }, [id]);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submission
    const handleSubmit = async () => {
        setIsSubmitting(true);  // Set submitting state to true

        try {
            if (isEditMode) {
                // Update the vertical data using PATCH
                await patchData(`/vertical`, {
                    id: id,
                    pabblyUrl: formData.pabblyUrl,
                    leadUrl: formData.leadUrl
                });
                toast.success("Vertical has been successfully updated!");  // Success toast
            } else {
                // Add new vertical using POST
                await postData('/vertical', formData);
                toast.success("Vertical has been successfully added!");  // Success toast
            }

            setTimeout(() => {
                navigate("/vertical");  // Redirect to the list of verticals after success
            }, 1500);  // Delay to allow toast to show
        } catch (error) {
            console.error("Error submitting vertical:", error);
            toast.error("An error occurred. Please try again.");  // Error toast
        } finally {
            setIsSubmitting(false);  // Reset submitting state
        }
    };

    // Meta title
    document.title = isEditMode ? "Update Vertical | Skote" : "Add Vertical | Skote";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title={isEditMode ? "Update Vertical" : "Add Vertical"}
                        breadcrumbItem={"Vertical"}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Vertical Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Vertical Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Pabbly URL</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="pabblyUrl"
                                            placeholder="Pabbly URL"
                                            value={formData.pabblyUrl}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Lead URL</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="leadUrl"
                                            placeholder="Lead URL"
                                            value={formData.leadUrl}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-4">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}  // Disable button while submitting
                                        >
                                            {isSubmitting
                                                ? (isEditMode ? "Updating..." : "Adding...")
                                                : (isEditMode ? "Update Vertical" : "Add Vertical")}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

AddVertical.propTypes = {
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default AddVertical;

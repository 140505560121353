
import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";

import ContentHeader from "../../components/common/ContentHeader";
import DomainSettingTable from "./SiteTable";

const Site = props => {
  //meta title
  document.title = "Site | Skote - React Admin & Domain Setting Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={"Site"}
            breadcrumbItem={"Site"}
          />
          <ContentHeader route={"add-site"} addNew={"Site"} />
          <DomainSettingTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

Site.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Site;

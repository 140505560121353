import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import Breadcrumb from "../../components/common/Breadcrumb";
import MonacoEditor from 'react-monaco-editor';
import { postData, patchData } from "../../helpers/api_helper";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AddQuiz = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const quizData = location.state ? location.state.rowData : null;

    const [isSubmitting, setIsSubmitting] = useState(false);  // State to track form submission
    const [formData, setFormData] = useState({
        quizName: "",
        progressBarType: "step",  // Default to 'Step' option
        progressBar: false,
        tcpa: false,
        quizJson: []
    });

    const [code, setCode] = useState("");  // Monaco editor value for displaying JSON

    useEffect(() => {
        if (quizData) {
            setFormData({
                quizName: quizData.quizName || "",
                progressBarType: quizData.progressBarType || "step",
                progressBar: quizData.progressBar || false,
                tcpa: quizData.tcpa || false,
                quizJson: quizData.quizJson || []
            });
            setCode(JSON.stringify(quizData.quizJson, null, 2)); // Pre-fill Monaco editor with quiz JSON
        }
    }, [quizData]);

    // Handle input change for text fields and select dropdown
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle checkbox changes
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    };

    // Handle Monaco editor changes to store JSON input
    const handleJsonChange = (newValue) => {
        setCode(newValue); // Updates the Monaco editor value (code)
        try {
            const parsedJson = JSON.parse(newValue);
            setFormData({
                ...formData,
                quizJson: parsedJson  // Directly update with parsed JSON
            });
        } catch (error) {
            console.error("Invalid JSON:", error);
        }
    };

    // Handle form submission: POST for adding, PATCH for editing
// Handle form submission: POST for adding, PATCH for editing
const handleSubmit = async () => {
    setIsSubmitting(true);  // Set submitting state to true

    try {
        if (quizData) {
            // Edit mode: send PATCH request to update the quiz
            const payload = {
                ...formData,
                id: quizData._id
            };
            await patchData(`/quiz`, payload);
            toast.success("Quiz updated successfully!");  // Success toast

            // Delay navigation to allow toast to appear
            setTimeout(() => {
                navigate(-1);  // Navigate back one page after successful update
            }, 1000);  // 1-second delay before navigating
        } else {
            // Add mode: send POST request to create a new quiz
            await postData('/quiz', formData);
            toast.success("Quiz added successfully!");  // Success toast

            // Reset form fields after submission
            setFormData({
                quizName: "",
                progressBarType: "step",
                progressBar: false,
                tcpa: false,
                quizJson: []
            });

            // Clear the Monaco editor
            setCode("");
            setTimeout(() => {
                navigate(-1);  // Navigate back one page after successful update
            }, 1000);
        }
    } catch (error) {
        console.error("Error saving quiz:", error);
        toast.error("Failed to save quiz. Please try again.");  // Error toast
    } finally {
        setIsSubmitting(false);  // Reset submitting state
    }
};


    const editorDidMount = (editor, monaco) => {
        editor.focus();
    };

    const options = {
        selectOnLineNumbers: true
    };

    document.title = quizData ? "Edit Quiz | Skote - React Admin & Quiz Setting Template" : "Add Quiz | Skote - React Admin & Quiz Setting Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb
                        title={"Quiz Settings"}
                        breadcrumbItem={quizData ? "Edit Quiz" : "Add Quiz"}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Quiz Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="quizName"
                                            placeholder="Quiz Name"
                                            value={formData.quizName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <label className="form-label">Quiz JSON</label>
                                        <MonacoEditor
                                            width="100%"
                                            height="600"
                                            language="json"
                                            value={code}
                                            options={options}
                                            onChange={handleJsonChange}
                                            editorDidMount={editorDidMount}
                                            theme="vs-dark"
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Progress Bar Type</label>
                                        <select
                                            className="form-control"
                                            name="progressBarType"
                                            value={formData.progressBarType}
                                            onChange={handleInputChange}
                                        >
                                            <option value="step">Step</option>
                                            <option value="percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div className="gap-5">
                                        <div className="mt-4 d-flex gap-2 align-items-center">
                                            <label className="form-label m-0 w-25">Enable Progress Bar</label>
                                            <div className="form-check form-switch form-switch-lg">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="progressBar"
                                                    name="progressBar"
                                                    checked={formData.progressBar}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4 d-flex gap-2 align-items-center">
                                            <label className="form-label m-0 w-25">Enable TCPA</label>
                                            <div className="form-check form-switch form-switch-lg">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="tcpa"
                                                    name="tcpa"
                                                    checked={formData.tcpa}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-4">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}  // Disable button while submitting
                                        >
                                            {isSubmitting
                                                ? (quizData ? "Updating..." : "Adding...")
                                                : (quizData ? "Update Quiz" : "Add Quiz")}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

AddQuiz.propTypes = {
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default AddQuiz;


import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";
import { useLocation, use } from "react-router-dom";
//Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";

import SiteConfigTable from "./SiteConfigTable";

const SiteConfig = props => {
  //meta title
  document.title = "Config | Skote - React Admin & Config Template";
  const location = useLocation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={"Site Config"}
            breadcrumbItem={"Site Config"}
          />
          
          <SiteConfigTable siteId={location?.state?.siteSetting} />
        </Container>
      </div>
    </React.Fragment>
  );
};

SiteConfig.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default SiteConfig;


import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";
import { getData } from "../../helpers/api_helper";

const Theme = props => {
  //meta title
  document.title = "Config | Skote - React Admin & Config Template";
  const [themeList, setThemeList] = useState([]);

  useEffect(() => {
    async function getThemeJsonList() {
      const themeData = await getData('/themes');
      setThemeList(themeData);
    }

    getThemeJsonList();
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={"Theme"}
            breadcrumbItem={"Theme"}
          />
          <ul>
            {themeList && themeList.map((theme) => {
              return <li>
                <Link
                  to={{ pathname: 'index/edit-theme' }}
                  state={{ themeId: theme['_id'], themeName: theme['name'] }}
                >
                  {theme['name']}
                </Link>
                </li>
            })}
          </ul>
        </Container>
      </div>
    </React.Fragment>
  );
};

Theme.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Theme;

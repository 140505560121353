
import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";

import ContentHeader from "../../components/common/ContentHeader";
import QuizTable from "./QuizTable";

const Quiz = props => {
  //meta title
  document.title = "Quiz | Skote - React Admin & Quiz Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={"Quiz"}
            breadcrumbItem={"Quiz"}
          />
          <ContentHeader route={"add-quiz"} addNew={"Quiz"} />
          <QuizTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

Quiz.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Quiz;

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import { getData, patchData, postData } from '../../helpers/api_helper';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddDomain = (props) => {
    const location = useLocation();  // Access the passed state (rowData)
    const navigate = useNavigate();  // For navigation after update
    const [verticalOptions, setVerticalOptions] = useState([]);
    const [configId, setConfigId] = useState(null); // Store the configId
    const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
    const [formData, setFormData] = useState({
        siteDomain: "",
        siteName: "",
        voluumUrl: "",
        gtmId: "",
        generator: "",
        vertical: ""
    });
    const [status, setStatus] = useState(location.state?.rowData?.status || "Inactive");
    const isEditMode = !!location.state?.rowData;  // Check if we're in "edit" mode
    const SitesStatus = {
        Active: "Active",
        Deleted: "Deleted",
        Inactive: "Inactive"
    };

    useEffect(() => {
        // Fetch vertical names and site config data
        const fetchVerticalsName = async () => {
            try {
                const response = await getData('/vertical');
                setVerticalOptions(response.data);
            } catch (error) {
                console.error("Error fetching verticals:", error);
            }
        };
        fetchVerticalsName();
    }, [])

    useEffect(() => {
        if (isEditMode) {
            // Fetch existing data only in edit mode
            const getSiteData = location.state?.rowData;
            if (getSiteData) {
                setFormData(getSiteData);
            }

            const getConfigId = async () => {
                try {
                    const response = await getData(`/site-config/get-config?siteId=${getSiteData?._id}`);
                    if (response.data) {
                        setConfigId(response.data._id); // Store the configId for future updates
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            voluumUrl: response.data.voluumUrl || "",
                            gtmId: response.data.gtmId || "",
                            generator: response.data.generator || "",
                            vertical: response.data.vertical._id || ""
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching site config:", error);
                }
            };

            getConfigId();
        }
    }, [location.state, isEditMode]);

    const handleSubmit = async () => {
        setIsSubmitting(true);  // Set loading state to true when submitting

        try {
            if (isEditMode) {
                const siteId = location.state.rowData._id;
                const payload = {
                    ...formData,
                    siteId,
                    status
                };

                if (configId) {
                    const siteConfigPayload = {
                        id: configId,
                        voluumUrl: formData.voluumUrl,
                        gtmId: formData.gtmId,
                        generator: formData.generator,
                        vertical: formData.vertical
                    };
                    await patchData(`/site-config`, siteConfigPayload);
                }

                await patchData(`/sites`, payload);
                toast.success("Site updated successfully!", );
                setTimeout(() => {
                    navigate(-1);
                }, 1000);  
            } else {
                await postData('/sites', formData);
                toast.success("Site added successfully!",);
                setTimeout(() => {
                    navigate("/site");
                }, 1000); 
            }

            // Reset form fields after successful submission
            setFormData({
                siteDomain: "",
                siteName: "",
                voluumUrl: "",
                gtmId: "",
                generator: "",
                vertical: ""
            });

        } catch (error) {
            console.error("Error saving site:", error.response ? error.response.data : error.message);
            toast.error("Failed to save site. Please try again.", { position: 'bottom-right' });
        } finally {
            setIsSubmitting(false);  // Set loading state to false after submission
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value); // Handle the change for the status dropdown
    };

    document.title = "Domain Setting | Skote - React Admin & Domain Setting Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb
                        title={"Domain Settings"}
                        breadcrumbItem={isEditMode ? "Edit Domain Setting" : "Add Domain Setting"}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Domain</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="siteDomain"
                                            placeholder="Domain"
                                            value={formData.siteDomain}
                                            onChange={handleInputChange}
                                            disabled={isEditMode}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Site Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="siteName"
                                            placeholder="Site Name"
                                            value={formData.siteName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Voluum URL</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="voluumUrl"
                                            placeholder="Voluum URL"
                                            value={formData.voluumUrl}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Google Tag Manager</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="gtmId"
                                            placeholder="Google Tag Manager"
                                            value={formData.gtmId}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Generator</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="generator"
                                            placeholder="Generator"
                                            value={formData.generator}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Vertical IDs</label>
                                        <select
                                            className="form-control"
                                            name="vertical"
                                            value={formData.vertical}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select Vertical</option>
                                            {verticalOptions.map(option => (
                                                <option key={option._id} value={option._id}>{option.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            {isEditMode && (
                                <Row>
                                    <Col lg={6}>
                                        <div className="mt-4">
                                            <label className="form-label">Site Status</label>
                                            <select
                                                className="form-control"
                                                name="status"
                                                value={status}
                                                onChange={handleStatusChange}  // Handle status change
                                            >
                                                {Object.values(SitesStatus).map((status) => (
                                                    <option key={status} value={status}>{status}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-4">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                            disabled={isSubmitting}  // Disable button while submitting
                                        >
                                            {isSubmitting ? (isEditMode ? 'Updating...' : 'Adding...') : (isEditMode ? 'Update Setting' : 'Add Setting')}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

AddDomain.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default AddDomain;

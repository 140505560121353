import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody
} from "reactstrap";

// Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";
import { useLocation } from 'react-router-dom';
import { getData, postData, patchData } from "../../helpers/api_helper";

const AddSiteConfig = props => {
    const { state } = useLocation();
    const { siteSettingId } = state;
    const [siteConfigId, setSiteConfigId] = useState('');
    const [verticalOptions, setVerticalOptions] = useState([]);

    // State to manage form input
    const [formData, setFormData] = useState({
        gtmId: "",
        generator: "",
        tiktokId: "",
        voluumUrl: "",
        fbId: "",
        shortCodes: {},
        ringbaUsers: [
            {
                username: "",
                key: "",
                staticNo: ""
            }
        ],
        vertical: {
            id: "",
            name: "",
            pabbly: ""
        }
    });

    useEffect(() => {
        // Fetch existing data to edit
        async function getSiteListData() {
            const response = await getData(`/site-config/get-config?siteId=${siteSettingId}`);
            setFormData({ ...formData, ...response.data });
            setSiteConfigId(response.data['_id']);
        }
        
        const fetchVerticalsName = async () => {
            try {
                const response = await getData('/vertical');
                setVerticalOptions(response.data);
            } catch (error) {
                console.error("Error fetching verticals:", error);
            }
        };

        fetchVerticalsName();
        getSiteListData();
    }, [siteSettingId]);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('ringbaUsers')) {
            // Handle the array structure for ringbaUsers
            const [ , index, field ] = name.match(/ringbaUsers\[(\d+)\]\.(\w+)/);
            const updatedRingbaUsers = [...formData.ringbaUsers];
            updatedRingbaUsers[index][field] = value;
            setFormData({
                ...formData,
                ringbaUsers: updatedRingbaUsers
            });
        } else if (name === 'vertical') {
            // Update vertical ID and name from the dropdown selection
            const selectedVertical = verticalOptions.find(option => option._id === value);
            setFormData({
                ...formData,
                vertical: {
                    id: selectedVertical._id,
                    name: selectedVertical.name,
                    pabbly: formData.vertical.pabbly // Retain the existing pabbly value
                }
            });
        } else if (name.startsWith('vertical.')) {
            const [nameKey, field] = name.split('.');
            setFormData({
                ...formData,
                [nameKey]: {
                    ...formData[nameKey],
                    [field]: value
                }
            });
        } else {
            // Handle other fields
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // Function to add a new Ringba User
    const addEmptyRingbaUserRow = () => {
        setFormData(prevState => ({
            ...prevState,
            ringbaUsers: [
                ...prevState.ringbaUsers,
                {
                    username: "",
                    key: "",
                    staticNo: ""
                }
            ]
        }));
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {
            // Prepare the payload by flattening the vertical and pabbly fields
            const updatedFormData = {
                ...formData,
                vertical: formData.vertical.id, // Send vertical ID directly
                pabbly: formData.vertical.pabbly // Send pabbly value separately
            };
    
            if (siteConfigId) {
                // Include the siteConfigId in the payload for update
                const payload = { ...updatedFormData, id: siteConfigId };
        
                // If there's a config ID, we update the existing record
                const response = await patchData(`/site-config`, payload);
                console.log('Config updated successfully:', response);
            } else {
                // If no config ID, we create a new record
                const response = await postData('/site-config', updatedFormData);
                console.log('New config created successfully:', response);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    
    

    // Meta title
    document.title = "Add User | Skote - React Admin & User Setting Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb
                        title={"Add Site Config"}
                        breadcrumbItem={"Add Site Config"}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">GTM ID</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="gtmId"
                                            placeholder="GTM ID"
                                            value={formData.gtmId}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Generator</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="generator"
                                            placeholder="Generator"
                                            value={formData.generator}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">TikTok ID</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="tiktokId"
                                            placeholder="TikTok ID"
                                            value={formData.tiktokId}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Voluum Url</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="voluumUrl"
                                            placeholder="Voluum Url"
                                            value={formData.voluumUrl}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Facebook ID</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="fbId"
                                            placeholder="Facebook ID"
                                            value={formData.fbId}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Short Codes</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="shortCodes"
                                            placeholder="Short Codes"
                                            value={formData.shortCodes}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <h4 className="mt-5 mb-0">Vertical</h4>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Vertical Name</label>
                                       <select
                                            className="form-control"
                                            name="vertical"
                                            value={formData.vertical.id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select Vertical</option>
                                            {verticalOptions.map(option => (
                                                <option key={option._id} value={option._id}>{option.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Pabbly</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="vertical.pabbly"
                                            placeholder="Pabbly"
                                            value={formData.vertical.pabbly}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <h4 className="mt-5 mb-0">Ringba User</h4>
                            {formData.ringbaUsers.map((user, index) => (
                                <Row key={index}>
                                    <Col lg={6}>
                                        <div className="mt-4">
                                            <label className="form-label">Ringba Username</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name={`ringbaUsers[${index}].username`}
                                                placeholder="Ringba Username"
                                                value={user.username}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mt-4">
                                            <label className="form-label">Ringba Key</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name={`ringbaUsers[${index}].key`}
                                                placeholder="Ringba Key"
                                                value={user.key}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mt-4">
                                            <label className="form-label">Ringba Static No</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name={`ringbaUsers[${index}].staticNo`}
                                                placeholder="Ringba Static No"
                                                value={user.staticNo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col lg={12}>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={addEmptyRingbaUserRow}
                                        >
                                            Add New User
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-4">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                        >
                                            Update Config
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

AddSiteConfig.propTypes = {
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default AddSiteConfig;

import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

// Import components
import TableContainer from '../../common/TableContainer';
import { getData } from "../../helpers/api_helper";
import { useNavigate } from 'react-router-dom'; 

const DomainSettingTable = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();  // For navigation

    useEffect(() => {
        // Fetch vertical names from the API
        const fetchSite = async () => {
            try {
                const response = await getData('/sites');  // Assuming the API response structure is an array of objects
                setData(response.data);  // Set the fetched data into state
            } catch (error) {
                console.error("Error fetching domain setting:", error);
            }
        };

        fetchSite();
    }, []);

    const deleteRow = (index) => {
        // Create a copy of the current data
        const updatedData = [...data];

        // Remove the item at the specified index
        updatedData.splice(index, 1);

        // Update local storage with the new array
        localStorage.setItem('domainSettings', JSON.stringify(updatedData));

        // Update the state to reflect the changes in the UI
        setData(updatedData);
    };

    const viewSiteConfig = (siteId, siteName) => {
        // Navigate to the site-config page with siteId and siteName as state
        navigate('/site-config', { state: { siteSetting: siteId, siteName: siteName } });
    };

    const editRow = (rowData) => {
        navigate('/site/add-site', { state: { rowData } });
    };

    const columns = useMemo(
        () => [
            {
                header: 'Domain Name',
                accessorKey: 'siteDomain',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Site Name',
                accessorKey: 'siteName',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <>
                        <div
                        style={{cursor:"pointer"}}
                            onClick={() => viewSiteConfig(row.original._id, row.original.siteName)}
                        >
                            {row.original.siteName}
                        </div>
                    </>
                ),
            },
            {
                header: 'Volume',
                accessorKey: 'volume',
                enableColumnFilter: false,
                enableSorting: true,
                cell:({row})=>(
                    <>
                    {
                        row.original.siteConfig.voluumUrl
                    }
                    </>
                )
            },
            {
                header: 'Google Tag Manager',
                accessorKey: 'googleTagManager',
                enableColumnFilter: false,
                enableSorting: true,
                cell:({row})=>(
                    <>
                    {
                        row.original.siteConfig.gtmId
                    }
                    </>
                )
            },
            {
                header: 'Generator',
                accessorKey: 'generator',
                enableColumnFilter: false,
                enableSorting: true,
                
            },
            {
                header: 'Vertical Id',
                accessorKey: 'verticalId',
                enableColumnFilter: false,
                enableSorting: true,
                cell:({row})=>(
                    <>
                    {
                        row.original.siteConfig.vertical
                    }
                    </>
                )
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Actions',
                accessorKey: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div>
                        <button
                            className="btn btn-primary btn-sm me-2"
                            onClick={() => editRow(row.original)}  // Pass row data to editRow
                        >
                            Edit
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteRow(row.index)}
                        >
                            Delete
                        </button>
                    </div>
                ),
            }
        ],
        [data]
    );

    // Meta title
    document.title = "Data Tables | Skote - Vite React Admin & Dashboard Template";

    return (
        <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search records..."
            pagination="pagination"
            paginationWrapper='dataTables_paginate paging_simple_numbers'
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
        />
    );
};

DomainSettingTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default DomainSettingTable;

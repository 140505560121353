import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import TableContainer from '../../common/TableContainer';
import { getData } from '../../helpers/api_helper'; 

const UserTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // To manage loading state
    const [error, setError] = useState(null); // To manage error state

    useEffect(() => {
        // Fetch data from the API
        const fetchUser = async () => {
            try {
                const response = await getData('/user');
                setData(response.data.users);  // Assuming users array is in response.data
                setLoading(false); // Turn off loading once data is fetched
            } catch (error) {
                console.error("Error fetching users:", error);
                setError("Failed to fetch users.");
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const deleteRow = (index) => {
        // Create a copy of the current data
        const updatedData = [...data];

        // Remove the item at the specified index
        updatedData.splice(index, 1);

        // Update the state to reflect the changes in the UI
        setData(updatedData);
    };

    const maskPassword = (password) => {
        return password ? '*****' : '';
    };

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Roles',
                accessorKey: 'roles',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <span>{row.original.roles.join(', ')}</span> // Join roles array to a string
                ),
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
            },
            // {
            //     header: 'Actions',
            //     accessorKey: 'actions',
            //     enableColumnFilter: false,
            //     enableSorting: false,
            //     cell: ({ row: { index } }) => (
            //         <button
            //             className="btn btn-danger btn-sm"
            //             onClick={() => deleteRow(index)}
            //         >
            //             Delete
            //         </button>
            //     ),
            // }
        ],
        [data]
    );

    // Meta title
    document.title = "User Settings | Skote - React Admin & Dashboard Template";

    return (
        <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search users..."
            pagination="pagination"
            paginationWrapper='dataTables_paginate paging_simple_numbers'
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
        />
    );
};

UserTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default UserTable;

import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Site from "../pages/Site";
import Vertical from "../pages/Vertical";
import Quiz from "../pages/Quiz";
import DomainSetting from "../pages/Site";
import AddDomain from "../pages/Site/AddSite";
import User from "../pages/User";
import AddUser from "../pages/User/AddUser";
import AddVertical from "../pages/Vertical/AddVertical";
import AddQuiz from "../pages/Quiz/AddQuiz";
import Theme from "../pages/Theme";
import SiteConfig from "../pages/SiteConfig";
import AddSiteConfig from "../pages/SiteConfig/AddSiteConfig";

const authProtectedRoutes = [
  { path: "/site", component: <Site /> },
  { path: "/site/add-site", component: <AddDomain /> },
  { path: "/vertical", component: <Vertical /> },
  { path: "/vertical/add-vertical", component: <AddVertical /> },
  { path: "/vertical/update/:id", component: <AddVertical /> },
  { path: "/quiz", component: <Quiz /> },
  { path: "/quiz/add-quiz", component: <AddQuiz /> },
  { path: "/theme", component: <Theme /> },
  { path: "/user", component: <User /> },
  { path: "/user/add-user", component: <AddUser /> },
  { path: "/site-config", component: <SiteConfig /> },
  { path: "/site-config/add-site-config", component: <AddSiteConfig /> },
  // //profile
  // { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/Site" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };

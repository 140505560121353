import apiClient from './apiClient';

// GET request with error handling
export async function getData(url) {
    try {
        const data = await apiClient.get(url);
        console.log('Data received:', data);
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

// POST request with error handling
export async function postData(url, payload) {
    try {
        const data = await apiClient.post(url, payload);
        console.log('Data posted successfully:', data);
        return data;
    } catch (error) {
        console.error('Error posting data:', error);
    }
}


// Delete request with error handling
export async function deleteData(url) {
  try {
      const data = await apiClient.delete(url);
      console.log('Data posted successfully:', data);
      return data;
  } catch (error) {
      console.error('Error posting data:', error);
  }
}

// patch request with error handling
export async function patchData(url, payload) {
  try {
      const data = await apiClient.patch(url, payload);
      console.log('Data posted successfully:', data);
      return data;
  } catch (error) {
      console.error('Error posting data:', error);
  }
}

/*
//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
*/
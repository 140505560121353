import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for redirection

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = (props) => {
  // Declare state variables
  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Admin");

  // Use useNavigate hook for redirection after logout
  const navigate = useNavigate();

  useEffect(() => {

    const storedUser = JSON.parse(localStorage.getItem("user"))
    if (storedUser) {
      setUsername(storedUser.name)
    }
  }, []);

  // Logout handler
  const handleLogout = () => {
    // Remove auth user/token from localStorage
    localStorage.removeItem("accessToken");

    // Optionally, you can remove the accessToken if stored separately
    // localStorage.removeItem("accessToken");

    // Navigate to login page
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href="/profile">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {"Profile"}
          </DropdownItem>
          <div className="dropdown-divider" />
          {/* Use a button to handle logout */}
          <DropdownItem onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;

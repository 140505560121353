
import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";
import ContentHeader from "../../components/common/ContentHeader";
import UserTable from "./UserTable";

const User = props => {
  //meta title
  document.title = "Domain Setting | Skote - React Admin & Domain Setting Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={"User"}
            breadcrumbItem={"User"}
          />
          <ContentHeader route={"add-user"} addNew={"User"} />
          <UserTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

User.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default User;

import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = ({ children }) => {
  const token = localStorage.getItem("accessToken"); // Check for authentication token

  if (!token) {
    // If no token, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If authenticated, render the protected component
  return <React.Fragment>{children}</React.Fragment>;
};

export default Authmiddleware;

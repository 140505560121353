import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'; // Import modal components

// Import components
import TableContainer from '../../common/TableContainer';
import { getData, deleteData } from "../../helpers/api_helper"; // Import deleteData
import { toast } from "react-toastify";

const VerticalTable = () => {
    const [data, setData] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete modal
    const [selectedVertical, setSelectedVertical] = useState(null); // State for selected vertical
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from the API
        const fetchVerticals = async () => {
            try {
                const response = await getData('/vertical');
                setData(response.data);
            } catch (error) {
                console.error("Error fetching verticals:", error);
            }
        };

        fetchVerticals();
    }, []);

    const handleEdit = (id) => {
        // Navigate to the edit page with the selected vertical ID
        navigate(`update/${id}`);
    };

    const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

    const handleDeleteClick = (vertical) => {
        setSelectedVertical(vertical); // Set the vertical to be deleted
        toggleDeleteModal(); // Open delete confirmation modal
    };

    const handleDelete = async () => {
        if (selectedVertical) {
            try {
                const response = await deleteData(`/vertical?id=${selectedVertical._id}`); // API call to delete vertical

                // Check if the response status is successful (e.g., 200)
                if (response.status === 200) {
                    toast.success("Deleted successfully"); // Success toast
                    toggleDeleteModal(); // Close modal after deletion
                } else {
                    // If not a 200 status, handle it as an error
                    toast.error(response.message || "Failed to delete vertical");
                }
            } catch (error) {
                console.error("Error deleting vertical:", error);

                // Handle error and show appropriate message
                const errorMessage = error.response?.data?.message || "You do not have permission to delete this vertical.";
                toast.error(errorMessage); // Error toast
            }
        }
    };


    const truncateText = (text, length = 20) => {
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };

    const columns = useMemo(
        () => [
            {
                header: 'Vertical Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Pabbly URL',
                accessorKey: 'pabblyUrl',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row: { original } }) => (
                    <span title={original.pabblyUrl}>
                        {truncateText(original.pabblyUrl, 60)}
                    </span>
                ),  // Truncate and show full URL on hover
            },
            {
                header: 'Lead URL',
                accessorKey: 'leadUrl',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row: { original } }) => (
                    <span title={original.leadUrl}>
                        {truncateText(original.leadUrl, 60)}
                    </span>
                ),  // Truncate and show full URL on hover
            },
            {
                header: 'Actions',
                accessorKey: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div className="d-flex gap-2">
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleEdit(original._id)}
                        >
                            Edit
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDeleteClick(original)} // Open delete modal
                        >
                            Delete
                        </button>
                    </div>
                ),
            }
        ],
        [data]
    );

    return (
        <>
            <TableContainer
                columns={columns}
                data={data || []}
                isGlobalFilter={true}
                isPagination={true}
                SearchPlaceholder="Search records..."
                pagination="pagination"
                paginationWrapper='dataTables_paginate paging_simple_numbers'
                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            />

            {/* Delete Confirmation Modal */}
            <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} size="lg">
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>
                    {`Are you sure you want to delete the vertical "${selectedVertical?.name}"?`}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Close
                    </Button>
                    <Button color="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

VerticalTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default VerticalTable;

import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { deleteData, getData } from '../../helpers/api_helper'; // Assuming you have a helper for API requests
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'; // Use Link for navigation

// Import components
import TableContainer from '../../common/TableContainer';
import MonacoEditor from "react-monaco-editor";
import { toast } from "react-toastify";

const QuizTable = () => {
    const navigate = useNavigate();  // For navigation
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // To manage loading state
    const [error, setError] = useState(null); // To manage error state
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false); // To track delete operation
    const [selectedJson, setSelectedJson] = useState(null); // Selected JSON for the modal
    const [quizData, setQuizData] = useState({});

    // Fetch data from the API
    const fetchQuizzes = async () => {
        try {
            const response = await getData('/quiz');
            setData(response.data.data);
            setLoading(false); // Turn off loading once data is fetched
        } catch (error) {
            console.error("Error fetching quizzes:", error);
            setError("Failed to fetch quizzes.");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQuizzes(); // Call the fetch function on component mount
    }, []);


    const editorDidMount = (editor, monaco) => {
        editor.focus();
    };
    const options = {
        selectOnLineNumbers: true
    };

    const handleDelete = (data) => {
        setQuizData(data);
        toggleDeleteModal(); // Open delete confirmation modal
    };

    const toggleModal = () => setIsModalOpen(!isModalOpen); // Toggle modal visibility
    const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen); // Toggle modal visibility

    const viewJson = (json) => {
        setSelectedJson(json); // Set the selected JSON data
        toggleModal(); // Open the modal
    };

    const editQuiz = (rowData) => {
        // Navigate to the add-domain page with row data passed as state
        navigate('/quiz/add-quiz', { state: { rowData } });
    };

    const handleQuizDelete = async (quizRowData) => {
        setIsDeleting(true); // Set deleting state to true
        try {
            const response = await deleteData(`/quiz?id=${quizRowData._id}`); // API call to delete the quiz
            toast.error(response.message)
            toggleDeleteModal(); // Close the modal after deletion
            fetchQuizzes(); // Refresh the quizzes after successful deletion
        } catch (error) {
            console.error("Error deleting quiz:", error);
            toast.error("Error deleting quiz")
        } finally {
            setIsDeleting(false); // Set deleting state to false
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'Quiz Name',
                accessorKey: 'quizName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Progress Bar Enabled',
                accessorKey: 'progressBar',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <span>{row.original.progressBar ? 'Yes' : 'No'}</span>
                ),
            },
            {
                header: 'Progress Bar Type',
                accessorKey: 'progressBarType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'TCPA Enabled',
                accessorKey: 'tcpa',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <span>{row.original.tcpa ? 'Yes' : 'No'}</span>
                ),
            },
            {
                header: 'Quiz JSON',
                accessorKey: 'quizJSON',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => viewJson(row.original.quizJson)}
                    >
                        View JSON
                    </button>
                ),
            },
            {
                header: 'Actions',
                accessorKey: 'actions',
                enableColumnFilter: false,
                enableSorting: false,
                cell: ({ row }) => (
                    <div className="d-flex">
                        <button
                            className="btn btn-primary btn-sm me-2"
                            onClick={() => editQuiz(row.original)}  // Pass row data to editRow
                        >
                            Edit
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(row.original)}
                        >
                            Delete
                        </button>
                    </div>
                )
            }
        ],
        [data]
    );

    if (loading) {
        return <div>Loading quizzes...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Meta title
    document.title = "Quiz Settings | Skote - React Admin & Dashboard Template";

    return (
        <>
            <TableContainer
                columns={columns}
                data={data || []}
                isGlobalFilter={true}
                isPagination={true}
                SearchPlaceholder="Search quizzes..."
                pagination="pagination"
                paginationWrapper='dataTables_paginate paging_simple_numbers'
                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            />

            {/* Modal to show JSON */}
            <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Quiz JSON</ModalHeader>
                <ModalBody>
                    <MonacoEditor
                        width="100%"
                        height="600"
                        language="json"
                        value={JSON.stringify(selectedJson,null, 2)}
                        theme="vs-dark"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} size="lg">
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>
                    {`Are you sure you want to delete the quiz "${quizData.quizName}"?`}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Close
                    </Button>
                    <Button color="danger" onClick={() => handleQuizDelete(quizData)}>
                        {isDeleting ? 'Deleting...' : 'Delete'} {/* Change text based on isDeleting state */}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

QuizTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default QuizTable;

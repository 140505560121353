
import PropTypes from "prop-types";
import React from "react";
import {
  Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";
import ContentHeader from "../../components/common/ContentHeader";
import VerticalTable from "./VerticalTable";

const Vertical = props => {
  //meta title
  document.title = "Vertical | Skote - React Admin & Vertical Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={"Vertical"}
            breadcrumbItem={"Vertical"}
          />
          <ContentHeader route={"add-vertical"} addNew={"Vertical"} />
          <VerticalTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

Vertical.propTypes = {
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Vertical;

import axios from 'axios';
// import accessToken from "./jwt-token-access/accessToken";

// Assume you have a function or method to get the token
// const token = accessToken; // Replace this with how you retrieve the token

const apiClient = axios.create({
    baseURL: 'https://dev.api.adstiacms.com', // Replace with your API base URL
    timeout: 10000, // Optional: Set a timeout for requests (10 seconds)
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor
apiClient.interceptors.request.use(
    (config) => {
        // Assume you have a method to get the token, e.g., from localStorage
        const token = localStorage.getItem('accessToken'); // Replace with how you get the token

        if (token) {
            // If the token exists, attach it to the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Response interceptor
apiClient.interceptors.response.use(
    (response) => {
        // Handle successful responses
        return response.data; // Return only the data part of the response
    },
    (error) => {
        // Handle errors
        if (error.response) {
            // Server responded with a status code outside the 2xx range
            handleServerError(error.response);
        } else if (error.request) {
            // No response received from the server
            console.error('No response received:', error.request);
        } else {
            // Something else happened while setting up the request
            console.error('Request setup error:', error.message);
        }
        return Promise.reject(error);
    }
);

// Function to handle different server errors
function handleServerError(response) {
    const { status, data } = response;
    switch (status) {
        case 400:
            console.error('Bad Request:', data.message || 'Invalid request.');
            break;
        case 401:
            console.error('Unauthorized:', data.message || 'Authentication required.');
            break;
        case 403:
            console.error('Forbidden:', data.message || 'Access denied.');
            break;
        case 404:
            console.error('Not Found:', data.message || 'Resource not found.');
            break;
        case 500:
            console.error('Internal Server Error:', data.message || 'An error occurred on the server.');
            break;
        default:
            console.error(`Unexpected error (status ${status}):`, data.message || 'An unknown error occurred.');
    }
}

export default apiClient;

import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody
} from "reactstrap";

// Import Breadcrumb
import Breadcrumb from "../../components/common/Breadcrumb";
import { postData } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify'; // Import Toast
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

const AddUser = (props) => {
    // State to manage form input
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        roles: "Media Buyers"
    });

    const userRole = {
        SuperAdmin: "Super Admin",
        Admin: "Admin",
        MediaBuyer: "Media Buyers"
    };

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle role change
    const handleRoleChange = (e) => {
        setFormData({
            ...formData,
            roles: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async () => {
        try {
            await postData('/user/create-user', formData);
            toast.success("User has been successfully created!", { position: "bottom-right" }); // Success toast

            // Reset form fields
            setFormData({
                name: "",
                email: "",
                password: "",
                roles: "Media Buyers"
            });
        } catch (error) {
            console.error('Error creating user:', error);
            toast.error("There was an error creating the user.", { position: "bottom-right" }); // Error toast
        }
    };

    // Meta title
    document.title = "Add User | Skote - React Admin & User Setting Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb
                        title={"Add User"}
                        breadcrumbItem={"Add User"}
                    />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label className="form-label">Email</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Password</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="password"
                                            placeholder="Password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mt-4">
                                        <label className="form-label">Role</label>
                                        <select
                                            className="form-control"
                                            name="roles"
                                            value={formData.roles}
                                            onChange={handleRoleChange}  // Handle role change
                                        >
                                            {Object.values(userRole).map((status) => (
                                                <option key={status} value={status}>
                                                    {status}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-4">
                                        <button 
                                            className="btn btn-primary" 
                                            onClick={handleSubmit}
                                        >
                                            Add User
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            {/* Toast Container for displaying notifications */}
            {/* <ToastContainer /> */}
        </React.Fragment>
    );
};

AddUser.propTypes = {
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default AddUser;
